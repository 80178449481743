* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;

}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background: #f5f5f5;
  background: url('../public/18606-Navy-Hexagon-Background.png') center center/cover no-repeat;

}

button {
  padding: 10px 20px;
  background: #021D49;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 15px;

}

.navbar {
  position: sticky;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 50px; 
  display: flex;
  justify-content: center;
  align-items: center;
  background: #185A7D;
  opacity: 0.9;
  border-bottom: 1px solid #185A7D;
}
.navbar .logo {
  color: #FFF;

}

.quiz-container {
  position: relative;
  margin: 50px auto 20px;
  width: 95%;
  max-width: 650px;
  min-height: 360px;
  background: #fff;
  border: 1px solid #eee;
  opacity: 0.9;
  box-shadow: 0px 2px 5px 2px rgba(0,0,0,0.05);
}

.indicator-container {
  position: relative;
  margin: 50px auto 20px;
  width: 95%;
  max-width: 650px;
  min-height: 30px;
  background: #fff;
  border: 1px solid #eee;
  opacity: 0.9;
  box-shadow: 0px 2px 5px 2px rgba(0,0,0,0.05);
  text-align: center;
  font-size: 15px;
  color: #555;
  visibility: hidden;

}

.logout-container {
  position: relative;
  margin: 50px 10px 20px 10px;
  width: 95%;
  max-width: 650px;
  min-height: 50px;
}

.join-screen {
  width: 100%;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  text-align: center;
  padding: 20px;  

}
.join-screen h2 {
  font-size: 25px;
  color: #021D49;

}

.join-screen p {
  max-width: 350px;
  color: #555;

}

.quiz-screen .question .progress-bar {
  width: 100%;
  height: 5px;

}

.quiz-screen .question .progress-bar.active {
  animation: progress 60s linear;
}

.quiz-screen .question .question-count {
  margin: 20px 0px;
  text-align: center;
  font-size: 15px;
  color: #555;

}

.quiz-screen .question .main {
  padding: 20px 40px;
  
}

.quiz-screen .question .main .title span {
  font-size: 15px;
  color: #555;
  font-weight: 600;


}

.quiz-screen .question .main .options {
  margin: 40px 0px 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.quiz-screen .question .main .options .option {
  width: calc(50% - 20px);
  border: 1px solid #bbb;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.quiz-screen .question .main .options .option.active {
  background: #185A7D;
  color: #fff;

}

.quiz-screen .question .control {
  padding: 10px 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #eee;

}

.result-screen {
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
@keyframes progress {
  0% {
    width: 0%;
    background: green;
  }
  79% {
    width: 79%;
    background: yellow;
  }
  80% {
    width: 80%;
    background: orangered;
  }
  100% {
    width: 100%;
    background: orangered;
  }
}


.fade-in {
  transition: opacity 1s ease;
}
.fade-out {
  opacity: 0;
  transition: opacity 1s ease;
}